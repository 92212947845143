<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <!-- <div class="vx-col w-full">
        <router-link to="/apps/projects">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>-->
      <div class="vx-col w-full">
        <vs-tabs>
          <!-- VIEW EXPENSE -->
          <vs-tab
            v-if="
              permissionPageAccessArry.includes(PermissionVariables.ViewCN) ||
              loginUserRole == 'Admin'
            "
            label="View Credit Note"
            icon-pack="feather"
            icon="icon-box"
          >
            <div>
              <expenses></expenses>
            </div>
          </vs-tab>
          <!-- EXPENSE STATUS -->

          <!-- <vs-tab
            v-if="
              permissionPageAccessArry.includes(
                PermissionVariables.ExpenseStatue
              ) || loginUserRole == 'Admin'
            "
            label="Expense Status"
            icon-pack="feather"
            icon="icon-box"
          >
            <div>
              <expense-status-view></expense-status-view>
            </div>
          </vs-tab> -->
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import expenses from "@/views/apps/expenses/creditNoteList.vue";
// import expenseStatusView from "@/views/apps/expenses/expenseStatusView.vue";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      isActive: false,
      loginUserRole: window.localStorage.getItem("UserRole"),
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      PermissionVariables: PermissionVariables,
    };
  },
  methods: {
    myFilter: function () {
      this.isActive = !this.isActive;
    },
  },
  components: {
    // expenseStatusView,
    expenses,
  },
};
</script>
<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
.con-vs-tabs .con-ul-tabs,
.vs-tabs--ul {
  background-color: #fff !important;
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul {
  background-color: #10163a !important;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul span {
  color: #c2c6dc;
  font-weight: bold;
}
</style>
